
import {defineComponent} from 'vue'
import {NButton} from "naive-ui";
import ButtonSquare from "@/components/ButtonSquare.vue";
import PartnersView from "@/views/PartnersView.vue";

export default defineComponent({
  name: "MissionHome",
  components: {PartnersView, NButton, ButtonSquare},
  data() {
    return {
      showMissionOne: false,
      showMissionTwo: false,
      showMissionThree: false,
    }
  },
  mounted() {
    const missionOne = this.$refs.missionOne as HTMLElement
    const missionTwo = this.$refs.missionTwo as HTMLElement
    const missionThree = this.$refs.missionThree as HTMLElement

    window.addEventListener('scroll', () => {
      const missionOneTop = missionOne.getBoundingClientRect().top + 150
      const missionTwoTop = missionTwo.getBoundingClientRect().top + 150
      const missionThreeTop = missionThree.getBoundingClientRect().top + 150

      if (missionOneTop < window.innerHeight) {
        this.showMissionOne = true
      }
      if (missionTwoTop < window.innerHeight) {
        this.showMissionTwo = true
      }
      if (missionThreeTop < window.innerHeight) {
        this.showMissionThree = true
      }
    })
  }
})
