
import {defineComponent} from 'vue';
import DonationBox from "@/components/DonationTierBox.vue";
import ButtonSquare from "@/components/ButtonSquare.vue";

export default defineComponent({
  name: 'JoinView',
  components: {ButtonSquare, DonationBox},
  data() {
    return {
      showMissionOne: false,
      showMissionTwo: false,
      showMissionThree: false,
      showMissionFour: false,
      dates: [
        {month: 'SEPT', day: '9'},
        {month: 'SEPT', day: '10'},
        {month: 'SEPT', day: '11'},
        {month: 'SEPT', day: '16'},
        {month: 'SEPT', day: '17'},
        {month: 'SEPT', day: '18'},
        {month: 'SEPT', day: '23'},
        {month: 'SEPT', day: '24'},
      ],
      museumPartners: [
        {image: 'ETHSLogo.png', link: 'http://www.easttnhistory.org/'},
        {image: 'LMULogo.png', link: 'https://www.lmunet.edu/'},
        {image: 'McCLungMuseum.png', link: 'https://mcclungmuseum.utk.edu/'},
        {image: 'kmalogo.png', link: 'https://knoxart.org/'},
        {image: 'BCM_Logo_dark.png', link: 'https://birthplaceofcountrymusic.org/'},
      ],
      theaterPartners: [
        {image: 'BIJOU.png', link: 'https://knoxbijou.org/'},
        {image: 'THEPOINT.jpg', link: 'https://thepointknox.com/'},
        {image: 'Pellissippi_State.png', link: 'https://thepointknox.com/'},
        {
          image: 'LMU_ARENA.png',
          link: 'https://lmurailsplitters.com/facilities/b-frank-tex-turner-arena/1'
        },
      ]
    }
  },
  mounted() {
    setTimeout(() => document.getElementById(this.$route.hash.replace('#', ''))?.scrollIntoView({behavior: "smooth"}), 200);

    const missionOne = this.$refs.missionOne as HTMLElement
    const missionTwo = this.$refs.missionTwo as HTMLElement
    const missionThree = this.$refs.missionThree as HTMLElement
    const missionFour = this.$refs.missionFour as HTMLElement

    window.addEventListener('scroll', () => {
      const missionOneTop = missionOne.getBoundingClientRect().top + 150
      const missionTwoTop = missionTwo.getBoundingClientRect().top + 150
      const missionThreeTop = missionThree.getBoundingClientRect().top + 150
      const missionFourTop = missionFour.getBoundingClientRect().top + 150

      if (missionOneTop < window.innerHeight) {
        this.showMissionOne = true
      }
      if (missionTwoTop < window.innerHeight) {
        this.showMissionTwo = true
      }
      if (missionThreeTop < window.innerHeight) {
        this.showMissionThree = true
      }
      if (missionFourTop < window.innerHeight) {
        this.showMissionFour = true
      }
    })
  },
  methods: {
    openLink(link: string) {
      if (link) {
        window.open(link, '_blank')
      }
    },
    openPdf() {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSfh825JCxxTWDgBGCUGrLLhqErJmcoQFou67o5Y3VLukL7xKQ/viewform', '_blank')
    }
  }
});
