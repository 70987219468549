
import {defineComponent} from 'vue'
import ButtonSquare from "@/components/ButtonSquare.vue";
import {NGi, NGrid} from "naive-ui";

export default defineComponent({
  name: "SponsorHome",
  components: {ButtonSquare, NGrid, NGi},
  data() {
    return {
      sponsorImages: [
        {image: 'BushBrothersLogo.png', link: 'https://www.bushbeans.com/en_US'},
        {image: 'Aubreys.svg', link: 'https://aubreysrestaurants.com/'},
        {image: 'FirstCenturyBankLogo.png', link: 'https://firstcenturybank.com/'},
        {image: 'SuperiorDrainageLogo.png', link: 'https://superiordrainageproducts.com/'},
        {image: 'StowersCATLogo.png', link: 'https://www.stowerscat.com/'},
        {image: 'DominionGroupLogo.png', link: 'https://www.dominiongroup.co/'},
        {image: 'DeRoyalLogo.png', link: 'https://www.deroyal.com/'},
        {image: 'BeverageControlLogo.png', link: 'https://www.beverage-control.com/'},
        {image: 'HaslamFoundationLogo.png', link: 'https://www.haslamgiving.org/who-we-are'},
        {image: 'GreaterSumVenturesLogo.png', link: 'https://greatersumventures.com/'},
        {image: 'PilotLogo.png', link: 'https://www.pilotcompany.com/'},
        {image: 'VisitKnoxvilleLogo.png', link: 'https://www.visitknoxville.com/'},
        {image: 'RTLodgeLogo.png', link: 'https://www.rtlodge.com/'},
        {image: 'GulfAndOhioRailwaysLogo.png', link: 'https://www.gulfandohio.com/'},
        {image: 'MountainMileLogo.png', link: 'https://www.mountainmilepigeonforge.com/'},
        {image: 'TheTrustCompanyLogo.png', link: 'https://thetrust.com/'},
        {image: 'TheHollingsworthCompaniesLogo.png', link: 'https://hollingsworthcos.com/'},
        {image: 'PinnacleFinancialLogo.png', link: 'https://www.pnfp.com/'},
        {image: 'AmeriplanBenefitCorpLogo.png', link: 'http://ameriplanbenefit.com'},
        {image: 'TeamHealthLogo.png', link: 'https://www.teamhealth.com/'},
        {image: 'MorganStanleyLogo.png', link: 'https://www.morganstanley.com/'},
        {image: 'LuckyGunnerLogo.png', link: 'https://www.luckygunner.com/'},
        {image: 'JAMurphyGroupLogo.png', link: 'https://jamurphygroup.com/'},
        {
          image: '21stCenturyMortgageLogo.png',
          link: 'https://www.21stmortgage.com/web/21stsite.nsf/index.html'
        },
        {image: 'VolunteerRealtyLogo.png', link: 'http://volrealty.com/'},
        {image: 'JudyTeasleyLogo.png', link: 'http://judyteasley.com/'},
        {image: 'BradensFurnitureLogo.png', link: 'https://shopbradens.com/'},
        {image: 'HomeTrustBankLogo.png', link: 'https://htb.com/'},
        {image: 'GiftAndGourmetLogo.png', link: 'https://www.gg-interiors.com/'},
        {image: 'SherriLeeLogo.png', link: ''},
        {image: 'TrotterIncLogo.png', link: ''},
        {image: 'JewelryTVLogo.png', link: 'https://www.jtv.com/'},
        {image: 'WallaceRealEstateLogo.png', link: 'https://wallacetn.com/'},
        {image: 'WynnGroupLogo.png', link: ''}
      ]
    }
  },
  methods: {
    openLink(link: string) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
})
